.show-education {
    padding: 20px;
}

.show-education-item {
    margin: 25px 0;
    padding: 20px;
    background-color: aliceblue;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.setup-schedule {
    
    gap: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;

}

.setup-schedule-top {
    margin-bottom: 40px;

    .p1 {
        color: black;
    }

    .p2 {
        margin-left: 13%;
        color: black;
    }

    .p3 {
        margin-left: 20%;
        color: black;
    }
}

.p-text {
    color: rgb(114, 126, 136);
    font-size: 15px;
}

.experience {
    margin: 20px;
    background-color: aliceblue;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.action {
    display: flex;
    flex-direction: row;
}