.video-card{
    text-align: center;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
    color: #7e8892;
    transition: .3s;
}

.video-card:hover {
    transform: scale(1.02);
    transition: .1s;
}