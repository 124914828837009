.aside-menu {
    color: rgb(67, 67, 45);
    display: block;
    text-decoration: none;    
}

.aside {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    margin: 0 20px;
    padding:  12px 10px;
    border-radius: 5px;
}

.aside:hover{
    background-color: rgba(221, 217, 217, 0.933)
}