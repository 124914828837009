.profile-container {
    max-width: 1800px;
    width: 100%;
    background-color: #E9EEF8;

    .wrapper {
        max-width: 900px;
        width: 50%;
        margin: auto;
        text-align: center;
        padding: 40px;
        background-color: #f7f8f9;

        img {
            max-width: 130px;
            width: 100%;
            height: 130px;
            border-radius: 50%;
            object-fit: cover;
            margin: 20px 0;
            cursor: pointer;
        }
    }

    .option {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
    }

    label {
        color: #76797f;
        text-align: left;
        margin-bottom: 7px;
    }

    .profile {
        color: white;
        display: inline-block;
        padding: 0 5px;
        border-radius: 6px;
        font-size: 16px;
        margin-top: -12px;
        cursor: pointer;
    }
}

@media (max-width: 1000px) {
    .profile-container {
        .wrapper {
            width: 80%;
        }
    }
}

@media (max-width: 600px) {
    .profile-container {
        .wrapper {
            width: 95%;
        }
    }
}