.patient-prescription-container {
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #E9EEF8;
    padding-bottom: 40px;
    border: #e0dddd 1px solid;
    border-radius: 4px;
    .patient-prescription-info-header {
        margin: auto;
        width: 75%;
        padding: 10px 20px 0px 20px;
        background-color: white;
    }
    .create-prescription-fields{
        background-color: white;
        width: 75%;
        height: 100%;
        padding: 10px;
        margin: auto;
    }
    .medicine-day-months-container {
        padding: 4px;
    }

    .save-prescription-button {
        background-color: #8010ab;
    }

    .save-prescription-button:hover {
        background-color: #7b1fa2;
        border-color: #0062cc;
    }

    .editable-text-field {
        border: 1px solid;
        border-color: #e5d9eb;
        position: relative;
        margin: 0px 8px;
        border-radius: 2px;
        width: 92%;
    }
    .button-container {
        padding-top: 20px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 20px;
    }
}


// For Phone Responsive
@media only screen and (max-width:576px) {
    .patient-prescription-container {
        margin: auto;
        width: 100%;
        background-color: #E9EEF8;
        padding-bottom: 40px;
        border: #e0dddd 1px solid;
        border-radius: 4px;
        .patient-prescription-info-header {
            margin: auto;
            width: 100%;
            padding: 10px;
            background-color: white;
        }
        .create-prescription-fields{
            background-color: white;
            width: 100%;
            margin: auto;
        }
    }
}

//For Tablet Responsive
@media only screen and (min-width: 577px) and (max-width: 992px) {
    .normal-appointments-container {
        .card-items {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            width: 100%;
            margin: auto;
            h4 {
                font-size: 2vw;
            }
        }
    }
    
    .urgent-appointments-container {
        .card-items {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            width: 100%;
            margin: auto;
            h4 {
                font-size: 2vw;
            }
        }
    }
}