.notification {
    padding: 20px;
   &:hover {
    background-color: rgb(235, 236, 237);
   }
   .time {
    display: inline;
    padding: 0 5px;
    border-radius: 10px;
    color: #1565C0;
    font-size: 10px;
    font-weight: 800;
   }
   p {
    margin-bottom: 10px;
   }
}

::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(152, 152, 152); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }