.register-container {
    .form {
       max-width: 500px;
       width: 100%;
       margin: auto;
       display: flex;
       flex-direction: column;
       margin-top: 30px;
       text-align: center;
       box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
       padding: 10px;
    }
 
    .icon {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: space-around;
       margin-top: 20px;
    }
 
    .icon-one,
    .icon-two {
       display: flex;
       flex-direction: row;
       align-items: center;
       justify-content: space-around;
    }
 
    .didntAnyAccount {
       font-weight: 400;
       margin-top: 70px;
       font-size: 14px;
    }
 
    h4 {
       margin-bottom: 20px;
       text-align: center;
       margin-top:  4px;
       margin-bottom: 50px;
       color: #8010AB;
    }
 
    h2 {
       text-align: center;
       color: #8010AB;
       margin: 0;
       font-size: 30px;
    }
 
    p {
       text-align: center;
       font-weight: 700;
       margin-top: 20px;
       color: #8010AB;
    }
 
    .icon img {
       height: 30px;
       width: 30px;
       margin-top: 14px;
       margin-right: 10px;
       object-fit: contain;
    }
 
    strong {
       text-align: center;
       color: #8010AB;
       cursor: pointer;
    }

 }