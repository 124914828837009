.videos-container {
    max-width: 1800px;
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-color: #E9EEF8;
    .videos-wrapper{
        width: 70%;
        padding: 40px 0;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: 50px;
        justify-items: center;
    }
}

// phone responsive
@media (max-width: 480px){
    .videos-container{
        .videos-wrapper{
            padding: 20px 0;
            width: 90%;
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }
    }
}

// For Tablet Responsive
@media only screen and (min-width: 577px) and (max-width: 992px) {
    .videos-container{
        .videos-wrapper{
            width: 90%;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
        }
    }
}