.login-container {
 
    h2 {
        color: #8010ab;
        margin-bottom: 10px;
    }
   
    .link {
        color: #8010ab;
    }
    .login-button {
        background-color: #8010ab;
    }
    .login-button:hover {
        background-color: #7b1fa2;
        border-color: #0062cc;
    }
    #forget-password {
        margin: 8px 0;
    }
    .paper {
        padding: 20px;
        height: 60vh;
        width: 280px;

        margin: 20px auto;
        margin-top: 100px;
        

    }
}
