.header-container {
    max-width: 1800px;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

    .header-wrapper {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

    }

    img {
        max-width: 150px;
        width: 100%;
        height: 50px;
        object-fit: contain;
        cursor: pointer;
    }


    a {
        list-style-type: none;
        text-decoration: none;
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }
    }

    .down-load {
        background-color: #8010AB;
        color: white;
        padding: 12px 20px;
        border-radius: 20px;
    }

    .menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 40px;
    }

    .icon {
        cursor: pointer;
    }

    .menu-icon {
        display: none;
        // background-color: #b275ca;
        color: rgb(70, 66, 66);
        border-radius: 5px;
        padding: 0 5px;
        font-size: 25px;
        border: 1px solid #b275ca;
    }
}

@media (max-width: 580px) {
    .header-container {
        .download-button {
            display: none;
        }
    }
}

@media (max-width: 480px) {
    .header-container {
        .m1 {
            display: none;
        }

        .menu-icon {
            display: block;
        }

    }
}