.all-appointments-container {
    max-width: 1800px;
    width: 100%;
    background-color: #E9EEF8;
    .tab-bar {
        position: sticky;
        background: #7b1fa2;  
    }
}

.normal-appointments-container {
    max-width: 1800px;
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-color: #E9EEF8;
    .card-items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        width: 86%;
        margin: auto;
        padding-right: 15px;
        h4 {
            color: #575656;
        }
        h5 {
            color: #5f5d5d;
        }
    }
}

.urgent-appointments-container {
    max-width: 1800px;
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-color: #E9EEF8;
    .card-items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        width: 86%;
        margin: auto;
        padding-right: 15px;
        h4 {
            color: #575656;
        }
        h5 {
            color: #5f5d5d;
        }
    }
}

.no-data-found-container{
    text-align: center;
    width: 100%;
    height: 100vh;
    background-color: #E9EEF8;
    h3{
        color: #7205a1;
    }
}


// For Phone Responsive
@media only screen and (max-width:576px) {
    .normal-appointments-container {
        width: 100%;
        .card-items {
            width: 100%;
            grid-template-columns: repeat(1, 1fr);
            gap: 13px;
            h4 {
                font-size: 4vw;
                color: #3a3939;
            }
            h5{
                color: #474646;
            }
        }
    }
    
    .urgent-appointments-container {
        width: 100%;
        .card-items {
            width: 100%;
            grid-template-columns: repeat(1, 1fr);
            gap: 13px;
            h4 {
                font-size: 4vw;
            }
            h5{
                color: #474646;
            }
        }
    }    
}

//For Tablet Responsive
@media only screen and (min-width: 577px) and (max-width: 992px) {
    .normal-appointments-container {
        .card-items {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            width: 100%;
            margin: auto;
            h4 {
                font-size: 2vw;
            }
        }
    }
    
    .urgent-appointments-container {
        .card-items {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            width: 100%;
            margin: auto;
            h4 {
                font-size: 2vw;
            }
        }
    }
}