.view-details-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #E9EEF8;
    height: 100;
    border: #fcfcfc 1px solid;
    padding-top: 20px;
    .view-details-card-container{
        border: #fcfcfc 1px solid;
        width: 70%;
        height: 280px;
        margin: 0 auto;
        .text-section-container{
            height: 40px;
            background-color: #9C27B0;
            border: rgb(166, 159, 167) 1px solid;
            border-radius: 2px;
            text-align: center;
            color: white;
            padding: 10px 0;
        }
    }
    .previous-appointment-container{
        width: 70%;
        margin: 0 auto;
        border: rgb(223, 220, 220) 1px solid;
        padding: 20px;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        height: 100%;
    }
}

// For Phone Responsive
@media only screen and (max-width:576px) {
    .view-details-container{
        padding-top: 5px;
        .view-details-card-container{
            width: 100%;
        }
        .previous-appointment-container{
            width: 100%;
            display: grid;
            grid-gap: 2px;
            padding:0px;
            grid-template-columns: repeat(1, 1fr);
        }
    }    
}
// For Tablet Responsive
@media only screen and (min-width: 577px) and (max-width: 992px) {
    .view-details-container{
        padding-top: 10px;
        .view-details-card-container{
            width: 90%;
        }
        .previous-appointment-container{
            width: 90%;
            display: grid;
            grid-gap: 2px;
            padding:0px;
            grid-template-columns: repeat(1, 1fr);
        }
    }    
}