.card-item-container{
    border-radius: 10px;
    .card-user-info-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.9;
    }
}

//For Tablet Responsive
@media only screen and (min-width: 577px) and (max-width: 992px) {
    .card-item-container{
        border-radius: 10px;
        .card-user-info-part {
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 1.7;
        }
    }
}