.education-container {
  max-width: 1800px;
  width: 100%;

  .wrapper {
    width: 80%;
    margin: auto;
    margin-top: 20px;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}

.educationswal {
  width: 300px !important;
  height: 300px !important;
}