.view-prescription-container {
    width: 75%;
    margin: auto;

    .view-prescription-paper-items {
        margin-bottom: 40px;
    }
    .logo-container {
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    .prescription-header {
        margin: auto;
        text-align: center;
        .date-and-time-container {
            display: flex;
            justify-content: center;
        }
    }
    .test-body-container {
        padding: 20px;
    }
}
