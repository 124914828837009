.dashboard-container {
  max-width: 1800px;
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: #E9EEF8;

  .dashboard-wrapper { 
    width: 80%;
    padding: 40px 0;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 20px;
    justify-items: center;
  }

  .box {
    text-align: center;
    height: 220px;
    max-width: 370px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
    color: #7e8892;
    transition: .3s;
  }

  .box:hover {
    transform: scale(1.03);
    transition: .3s;
  }

  .doctor {
    width: 80%;
    margin: auto;
    background-color: #fff;
  }

  img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }

  .icon {
    font-size: 60px;
    margin-top: 50px;
    color: #8010AB;
  }

  .icon-4 {
    color: red;
  }
}


@media (max-width: 768px){
 .dashboard-container {
  .dashboard-wrapper{
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
  }
 }
}


@media (max-width: 480px){
  .dashboard-container {
   .dashboard-wrapper{
     grid-template-columns: repeat(1,1fr); 
     width: 95%;
   }
   .box {
     max-width: 440px;
   }
   
  }
 }