.accounts-container {
    max-width: 1800px;
    width: 100%;
    background-color: #E9EEF8;
    height: 100vh;

    .accounts-wrapper {
        width: 80%;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        justify-items: center;
        padding-top: 70px;
    }

    .box {
        text-align: center;
        height: 220px;
        max-width: 370px;
        width: 100%;
        background-color: #fff;
        border-radius: 10px;
        color: #7e8892;
        transition: .3s;

    }

    h3 {
        margin-top: 60px;
    }

    .text {
        width: 80%;
        margin: auto;
        color: #7e8892;
        margin-top: 130px;

        p {
            margin: 6px 0;
        }
    }
    span {
        font-weight: 800;
    }
    .t {
        display: flex;
        align-items: center;
        gap: 6px;
    }
}


@media (max-width: 900px){
    .accounts-container {
        .accounts-wrapper {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media (max-width: 750px){
    .accounts-container {
        .accounts-wrapper {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (max-width: 480px){
    .accounts-container {
        height: auto;
        .accounts-wrapper {
            width: 95%;
            grid-template-columns: repeat(1, 1fr);
        }
        .box {
            max-width: 450px;
        }
    }
}