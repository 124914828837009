.notifications-container {
    background-color: #E9EEF8;
    max-width: 1800px;
    width: 100%;
    height: auto;
    min-height: 100vh;


    .notifications-wrapper {
        width: 70%;
        margin: auto;
        padding: 30px;
    }

    .notification {

        background-color: white;
        padding: 20px 10px;
        border-radius: 10px;
        margin-top: 13px;
    }

    .notification:first-child {
        margin-top: 0;
    }
}