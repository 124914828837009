.password-change-container {
    max-width: 1800px;
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-color: #E9EEF8;
    padding: 50px 0;

    .box {
        text-align: center;
        height: 350px;
        max-width: 500px;
        width: 100%;
        background-color: #fff;
        border-radius: 10px;
        color: #7e8892;
        margin: auto;
        padding-top: 40px;

    }

    h1 {
        text-align: center;
        color: rgb(87, 114, 127);

    }

    p {
        text-align: center;
        margin-bottom: 30px;
        color: rgb(87, 114, 127);
    }
}

@media (max-width: 480px) {
    .password-change-container {
        .box {
            width: 95%;
        }
    }
}