.bankInformation-container {
    max-width: 1800px;
    width: 100%;
    background-color: #E9EEF8;
    height: auto;
    padding: 40px 0;


    .wrapper {
        max-width: 400px;
        width: 100%;
        padding: 60px 0;
        margin: auto;
        background-color: white;
        padding: 20px 30px;
        border-radius: 10px;
        height: 680px;
    }

    .accInfo {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .label {
        padding: 10px 0;
        margin-top: 10px;
        display: block;
    }
    .l{
        margin-bottom: -20px;
      
    }
    .l3{
          margin-top: 20px;
    }
    .l4{
        margin-top: -10px;
    }
    .l5{
        margin-top: -13px;
    }
}

@media (max-width: 410px) {
    .bankInformation-container {
        .wrapper {
            width: 95%;
        }
    }
}